import {
  MessageInputContainer,
  MessagesContainer,
} from "./PlayerChats.styled";
import PlayerMessages from "./Messages/PlayerMessages";
import { useEffect, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { AllMessagesWrapper } from "./Messages/Messages.styled";
import Loader from "../common/Loader";

function MessagesDemo({}) {
  const [timeoutReached, setTimeoutReached] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeoutReached(true);
    }, 10000);

    return () => clearTimeout(timer); // Cleanup timeout if component unmounts
  }, []);

  return (
    <MessagesContainer $userType={"user"}>
        <>
          <AllMessagesWrapper>
            <div className={"loading"}>
              {!timeoutReached ? (
                <>
                  <Icon icon="line-md:loading-alt-loop" width="36" height="36" />
                  <div>Almost there! </div>
                  <div>Our support team will be with you shortly... 💬✨</div>
                </>
              ) : (
                <>
                <div onClick={() => window.location.reload()}>⚠️ Oops! This is taking a bit long.⚠️</div>
                <div onClick={() => window.location.reload()}>✨ Give it another go ! Click here to refresh! 😊</div>
                </>
              )}

            </div>
          </AllMessagesWrapper>
            <MessageInputContainer $disableSend={true}>
              <input
                placeholder="Text Here..."
                type="text"
                disabled={true}
              />
                <div className="file-input_container">
                  <label className="file-label">
                    <Icon icon="iconoir:attachment" fontSize={22} style={{ color: "#00BCD4" }} />
                  </label>
                </div>
                <div className="sendButton">
                  <Icon icon="mynaui:send-solid" fontSize={22}  
                />
              </div>
            </MessageInputContainer>
        </>
    </MessagesContainer>
  );
}

export default MessagesDemo;
